// // // // import logo from './logo.svg';
// // // // import './App.css';

// // // // function App() {
// // // //   return (
// // // //     <div className="App">
// // // //       <header className="App-header">
// // // //         <img src={logo} className="App-logo" alt="logo" />
// // // //         <p>
// // // //           Edit <code>src/App.js</code> and save to reload.
// // // //         </p>
// // // //         <a
// // // //           className="App-link"
// // // //           href="https://reactjs.org"
// // // //           target="_blank"
// // // //           rel="noopener noreferrer"
// // // //         >
// // // //           Learn React
// // // //         </a>
// // // //       </header>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default App;


// // // import React from "react";
// // // import "./App.css";

// // // function App() {
// // //   const handleDownload = () => {
// // //     const link = document.createElement("a");
// // //     link.href = "/path-to-your-apk-file/app-release.apk"; // Replace with your APK file path
// // //     link.download = "app-release.apk";
// // //     document.body.appendChild(link);
// // //     link.click();
// // //     document.body.removeChild(link);
// // //   };

// // //   return (
// // //     <div className="landing-page">
// // //       {/* Header Section */}
// // //       <header className="header">
// // //         <h1>Welcome to Our App</h1>
// // //         <p>Your trusted partner for amazing features on the go!</p>
// // //       </header>

// // //       {/* Download Section */}
// // //       <section className="download-section">
// // //         <h2>Download the App</h2>
// // //         <button onClick={handleDownload} className="download-button">
// // //           Download APK
// // //         </button>
// // //       </section>

// // //       {/* Support Section */}
// // //       <section className="support-section">
// // //         <h2>Support</h2>
// // //         <p>If you need assistance, feel free to reach out to us:</p>
// // //         <ul>
// // //           <li>Email: <a href="mailto:support@example.com">support@example.com</a></li>
// // //           <li>Phone: <a href="tel:+123456789">+123 456 789</a></li>
// // //         </ul>
// // //       </section>

// // //       {/* Footer */}
// // //       <footer className="footer">
// // //         <p>&copy; 2024 Your Company Name. All rights reserved.</p>
// // //       </footer>
// // //     </div>
// // //   );
// // // }

// // // export default App;



// // import React from "react";
// // import "./App.css";
// // import img from './Assets/titali1.avif';
// // import img1 from './Assets/gp.png';
// // import img3 from './Assets/phonepay.png';
// // import img4 from './Assets/pay.webp';

// // function App() {
// //   const handleDownload = (platform) => {
// //     const link = document.createElement("a");
// //     if (platform === "windows") {
// //       link.href = "/path-to-your-windows-apk/windows-app.apk"; // Replace with your Windows APK file path
// //       link.download = "windows-app.apk";
// //     } else if (platform === "mac") {
// //       link.href = "/path-to-your-mac-apk/mac-app.apk"; // Replace with your Mac APK file path
// //       link.download = "mac-app.apk";
// //     }
// //     document.body.appendChild(link);
// //     link.click();
// //     document.body.removeChild(link);
// //   };

// //   return (
// //     <div className="landing-page">
// //       {/* Navbar */}
// //       <nav className="navbar">
// //         <ul>
// //           <li><a href="#about">About</a></li>
// //           <li><a href="#faq">FAQ</a></li>
// //           <li><a href="#help">Help</a></li>
// //         </ul>
// //       </nav>

// //       {/* Hero Section */}
// //       <section className="hero-section">
// //         <img
// //           src={img} // Replace with your image path
// //           alt="Hero Banner"
// //           className="hero-image"
// //         />
// //       </section>

// //       {/* Download Section */}
// //       <section className="download-section">
// //         <div className="download-options">
// //           <div className="download-option">
// //             <h3>Download for Windows</h3>
// //             <button
// //               className="download-button1"
// //               onClick={() => handleDownload("windows")}
// //             >
// //               Download Windows APK
// //             </button>
// //           </div>
// //           <div className="download-option">
// //             <h3>Download for Mac</h3>
// //             <button
// //               className="download-button2"
// //               onClick={() => handleDownload("mac")}
// //             >
// //               Download Mac APK
// //             </button>
// //           </div>
// //         </div>
// //       </section>

// //       {/* Promotion Section */}
// //       <section className="promotion-section">
// //         <h2>Download and Get Exclusive Offers</h2>
// //         <h3>Withdraw Winnings Instantly</h3>
// //         <div className="payment-logos">
// //           <img src={img1} alt="GPay" />
// //           <img src={img3} alt="Paytm" />
// //           <img src={img4} alt="PhonePe" />
// //         </div>
// //       </section>

// //       {/* Trusted People Section */}
// //       <section className="trusted-section">
// //         <h2>Trusted by Thousands</h2>
// //         <div className="trusted-images">
// //           <img src="/path-to-image/person1.jpg" alt="Person 1" />
// //           <img src="/path-to-image/person2.jpg" alt="Person 2" />
// //           <img src="/path-to-image/person3.jpg" alt="Person 3" />
// //         </div>
// //       </section>

// //       {/* Features Section */}
// //       <section className="features-section">
// //         <div className="feature">
// //           <h3>Best in Class Gaming Experience</h3>
// //         </div>
// //         <div className="feature">
// //           <h3>Top-Notch Security</h3>
// //         </div>
// //         <div className="feature">
// //           <h3>Best Offers and Bonuses</h3>
// //         </div>
// //       </section>

// //       {/* Footer */}
// //       <footer className="footer">
// //         <p>&copy; 2024 Your Company Name. All rights reserved.</p>
// //       </footer>
// //     </div>
// //   );
// // }

// // export default App;



// import React, { useEffect } from "react";
// import "./App.css";
// import { FaInfoCircle, FaQuestionCircle, FaLifeRing } from "react-icons/fa"; // Import icons
// import img from './Assets/titali1.avif';
// import img1 from './Assets/gp.png';
// import img3 from './Assets/phonepay.png';
// import img4 from './Assets/pay.webp';
// import apk from '../public/app-debug (1).apk';



// function App() {
//   const handleDownload = (platform) => {
//     const link = document.createElement("a");
//     if (platform === "windows") {
//       link.href = {apk}// Replace with your Windows APK file path
//       link.download = "windows-app.apk";
//     } else if (platform === "mac") {
//       link.href = "/path-to-your-mac-apk/mac-app.apk"; // Replace with your Mac APK file path
//       link.download = "mac-app.apk";
//     }
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   useEffect(() => {
//     const balls = Array.from({ length: 10 }, () => document.createElement("div"));
//     const container = document.querySelector(".hero-section");
//     balls.forEach((ball) => {
//       ball.className = "ball";
//       container.appendChild(ball);
//     });
//     animateBalls(balls);
//   }, []);

//   const animateBalls = (balls) => {
//     balls.forEach((ball) => {
//       ball.style.left = Math.random() * 100 + "vw";
//       ball.style.animationDuration = Math.random() * 4 + 3 + "s";
//     });
//   };

//   return (
//     <div className="landing-page">
//       {/* Navbar */}
   

//       {/* Marquee */}
//       {/* <marquee className="marquee">Welcome to Titali Gaming</marquee> */}

//       {/* //Hero Section */}
//       {/* <section className="hero-section">
//         <img
//           src={img}
//           alt="Hero Banner"
//           className="hero-image"
//         />
//       </section> */}

// <div className="hero-section">
//   <div className="marquee-animation">
    
//    <p>Welcome to Titali Gaming! 🎮✨ </p> 
//    <p>Your trusted partner for amazing features on the go!</p> 
   
//   <img
//     src={img} // Replace with your image path
//     alt="Hero Banner"
//     className="hero-image"
//   />
// </div>
// </div>



//       {/* Download Section */}
//       <section className="download-section">
//         <div className="download-options">
//           <div className="download-option">
//             <h3>Download for Windows</h3>
//             <button
//               className="download-button1"
//               onClick={() => handleDownload("windows")}
//             >
//               Download Windows APK
//             </button>
//           </div>
//           <div className="download-option">
//             <h3>Download for Mac</h3>
//             <button
//               className="download-button2"
//               onClick={() => handleDownload("mac")}
//             >
//               Download Mac APK
//             </button>
//           </div>
//         </div>
//       </section>

//       {/* Promotion Section */}
//       <section className="promotion-section">
//         <h2>Download and Get Exclusive Offers</h2>
//         <h3>Withdraw Winnings Instantly</h3>
//         <div className="payment-logos">
//           <img src={img1} alt="GPay" />
//           <img src={img3} alt="Paytm" />
//           <img src={img4} alt="PhonePe" />
//         </div>
//       </section>
//  {/* Trusted People Section */}
//       <section className="trusted-section">
//         <h2>Trusted by Thousands</h2>
//         <div className="trusted-images">
//          <img src="/path-to-image/person1.jpg" alt="Person 1" />
//         <img src="/path-to-image/person2.jpg" alt="Person 2" />
//           <img src="/path-to-image/person3.jpg" alt="Person 3" />
//          </div>
//       </section>


//       {/* Features Section */}
//       <section className="features-section">
//         <div className="feature">
//           <h3>Best in Class Gaming Experience</h3>
//           <p>Enjoy top-notch graphics.</p>
//         </div>
//         <div className="feature">
//           <h3>Top-Notch Security</h3>
//           <p>Secure your data always.</p>
//         </div>
//         <div className="feature">
//           <h3>Best Offers and Bonuses</h3>
//           <p>Exciting rewards await.</p>
//         </div>
//       </section>

//       {/* New Section */}
//       <section className="new-section">
//         <h2>Why Choose Titali Gaming?</h2>
//         <p>With our intuitive interface and exciting games, we bring a platform where fun meets security and convenience.</p>
//       </section>

//       {/* Footer */}
//       <footer className="footer">
//         <p>&copy; 2024 Titali Gaming. All rights reserved.</p>
//       </footer>
//     </div>
//   );
// }

// export default App;


import React, { useEffect } from "react";
import "./App.css";
import img from './Assets/titali1.avif';
import img1 from './Assets/gp.png';
import img3 from './Assets/phonepay.png';
import img4 from './Assets/pay.webp';

function App() {
  const handleDownload = (platform) => {
    const link = document.createElement("a");
    if (platform === "windows") {
      link.href = "/app-debug.apk"; // Ensure the APK is in the 'public' folder with this name
      link.download = "titali-gaming-windows.apk";
    } else if (platform === "mac") {
      link.href = "/app-debug.apk"; // Same APK can be used if relevant
      link.download = "titali-gaming-mac.apk";
    }
    link.click();
  };

  useEffect(() => {
    const balls = Array.from({ length: 10 }, () => document.createElement("div"));
    const container = document.querySelector(".hero-section");
    balls.forEach((ball) => {
      ball.className = "ball";
      container.appendChild(ball);
    });
    animateBalls(balls);
  }, []);

  const animateBalls = (balls) => {
    balls.forEach((ball) => {
      ball.style.left = Math.random() * 100 + "vw";
      ball.style.animationDuration = Math.random() * 4 + 3 + "s";
    });
  };

  return (
    <div className="landing-page">
      <div className="hero-section">
        <div className="marquee-animation">
          <p>Welcome to Titali Gaming! 🎮✨</p>
          <p>Your trusted partner for amazing features on the go!</p>
          <img
            src={img}
            alt="Hero Banner"
            className="hero-image"
          />
        </div>
      </div>

      {/* Download Section */}
      <section className="download-section">
        <div className="download-options">
          <div className="download-option">
            <h3>Download for Windows</h3>
            <button
              className="download-button1"
              onClick={() => handleDownload("windows")}
            >
              Download Windows APK
            </button>
          </div>
          <div className="download-option">
            <h3>Download for Mac</h3>
            <button
              className="download-button2"
              onClick={() => handleDownload("mac")}
            >
              Download Mac APK
            </button>
          </div>
        </div>
      </section>

      {/* Promotion Section */}
      <section className="promotion-section">
        <h2>Download and Get Exclusive Offers</h2>
        <h3>Withdraw Winnings Instantly</h3>
        <div className="payment-logos">
          <img src={img1} alt="GPay" />
          <img src={img3} alt="Paytm" />
          <img src={img4} alt="PhonePe" />
        </div>
      </section>

      {/* Trusted People Section */}
      <section className="trusted-section">
        <h2>Trusted by Thousands</h2>
        <div className="trusted-images">
          <img src="/path-to-image/person1.jpg" alt="Person 1" />
          <img src="/path-to-image/person2.jpg" alt="Person 2" />
          <img src="/path-to-image/person3.jpg" alt="Person 3" />
        </div>
      </section>
      <section className="support">
        <div className="support">
        <h2>Support</h2>
        <p>Contact Number: 8828963246</p>
        <p>Email: titaligame@gmail.com</p>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <div className="feature">
          <h3>Best in Class Gaming Experience</h3>
          <p>Enjoy top-notch graphics.</p>
        </div>
        <div className="feature">
          <h3>Top-Notch Security</h3>
          <p>Secure your data always.</p>
        </div>
        <div className="feature">
          <h3>Best Offers and Bonuses</h3>
          <p>Exciting rewards await.</p>
        </div>
      </section>

      {/* New Section */}
      <section className="new-section">
        <h2>Why Choose Titali Gaming?</h2>
        <p>With our intuitive interface and exciting games, we bring a platform where fun meets security and convenience.</p>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Titali Gaming. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;